import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function contentCards(state, action) {
  const initContentCardState = initialState?.contentCards || {};
  state = typeof state === 'undefined' ? initContentCardState : state;

  switch (action.type) {
    case types.GET_CONTENT_CARDS: {
      return {
        ...state,
        ...initContentCardState,
        ...{
          inProgress: true,
        },
      };
    }
    case types.GET_CONTENT_CARDS_SUCCESS: {
      const { contentCards } = action;
      return {
        ...state,
        ...{
          isReady: true,
          inProgress: false,
          isSuccess: true,
          data: contentCards,
        },
      };
    }
    case types.GET_CONTENT_CARDS_FAILURE: {
      return {
        ...state,
        ...{
          isReady: true,
          inProgress: false,
          isSuccess: false,
          data: [],
        },
      };
    }
    default: {
      return state;
    }
  }
}
