import { initObserver } from './user';
import getFlags from 'ducks/features';

const isConfigReady = (state) => state?.systemConfig?.isReady;

const isUserAndConfigReady = (state) =>
  initObserver(state) && isConfigReady(state);

const isContentCardsReady = (state) => state?.contentCards?.isReady;

const getContentCards = (state) => {
  if (!isContentCardsReady(state)) return false;
  const contentCards = state?.contentCards?.data || [];
  return contentCards;
};

const getContentCardsState = (state) => state?.contentCards;

const getContentCardsInObj = (state) => {
  const contentCardsState = getContentCardsState(state);
  if (isConfigReady(state)) {
    const getFeatureFlags = getFlags({ systemConfig: state.systemConfig });
    const {
      enabled: isPromotionalPlacementEnabled,
      backendValidation: isPromotionalPlacementBackendValidation,
    } = getFeatureFlags('PROMOTIONAL_PLACEMENTS_CONFIG');

    if (
      !isPromotionalPlacementEnabled ||
      !isPromotionalPlacementBackendValidation
    ) {
      return {
        ...contentCardsState,
        // if BE validation is not enabled, override isReady so the UI can skip loading
        isReady: true,
      };
    }

    const contentCards = getContentCards(state);
    if (contentCards) {
      const contentCardsInObj = contentCards.reduce((acc, asset) => {
        acc[asset.id] = { ...asset };
        return acc;
      }, {});

      return {
        ...contentCardsState,
        data: contentCardsInObj,
        dataExtra: contentCards.map((contentCard) => {
          const { id, contentCard: contentCardData } = contentCard;

          const bucketKey = contentCardData?.bucketKey;
          const dataSource = contentCardData?.dataSource;
          return {
            id,
            bucketKey,
            dataSource,
          };
        }),
      };
    }
  }

  return contentCardsState;
};

const getContentCardActionConfig = (promotionalPlacementsConfig) =>
  promotionalPlacementsConfig?.contentCard?.actions || {};

export {
  isUserAndConfigReady,
  getContentCardsInObj,
  getContentCardActionConfig,
};
