import * as braze from '@braze/web-sdk';
import defaultConfig from 'constants/defaultConfig';
import { isMobile, isSNSWebView } from 'utils/userAgent';

export function init(props) {
  if (!props) {
    console.error('Missing init props');
    return false;
  }

  const {
    brazeApiKey,
    brazeBrowserApiKey,
    brazeMobileApiKey,
    brazeSdkEndpoint,
  } = defaultConfig;

  const { userId, initOption, option, featureConfig } = props;

  // -- Start braze api key calculation
  // OSN-2668 - use different braze api key/app for different web segments
  // to track usage
  let finalBrazeApiKey = brazeApiKey;
  const { filterBrowser, filterMobile } = featureConfig || {};
  const isMobileDevice = filterMobile && isMobile();
  if (isSNSWebView(filterBrowser)) {
    finalBrazeApiKey = brazeBrowserApiKey;
  } else if (isMobileDevice) {
    finalBrazeApiKey = brazeMobileApiKey;
  }
  // -- End braze api key calculation

  const initOptionDefault = { baseUrl: brazeSdkEndpoint };
  braze.enableSDK();
  const isBrazeInitialized = braze.initialize(finalBrazeApiKey, {
    ...initOptionDefault,
    ...initOption,
  });

  if (option?.automaticallyShowInAppMessages) {
    braze.automaticallyShowInAppMessages();
  }
  userId && braze.changeUser(userId);

  braze.openSession();

  return isBrazeInitialized;
}
