import API from '@aws-amplify/api';

import * as types from '../constants/actionTypes';
import getFlags from 'ducks/features';
import contentCardsMapper from 'middleware/contentCardMapping';

export function getContentCards(userId) {
  return (dispatch, getState) => {
    const state = getState();
    const { systemConfig } = state;
    const getFeatureFlags = getFlags({
      systemConfig,
    });
    const promotionalPlacementsConfig = getFeatureFlags(
      `PROMOTIONAL_PLACEMENTS_CONFIG`,
    );
    const {
      enabled: isPromotionalPlacementsEnabled,
      backendValidation: isBackendValidationEnabled,
      contentCard: { actions: contentCardActionsConfig } = {},
    } = promotionalPlacementsConfig || {};
    if (!isPromotionalPlacementsEnabled || !isBackendValidationEnabled)
      return false;

    const path = userId && userId !== '-1' ? `/users/${userId}/web` : `/web`;

    dispatch(setContentCards());
    return API.get('fe-api-getContentCards', path, {})
      .then((result) => {
        const contentCards = result?.data?.assets;

        const contentCardsMapped = contentCardsMapper({
          contentCards,
          contentCardActionsConfig,
        });
        dispatch(setContentCardsSuccess(contentCardsMapped));
      })
      .catch((error) => {
        dispatch(setContentCardsFailure());
        throw error;
      });
  };
}

export function setContentCards() {
  return {
    type: types.GET_CONTENT_CARDS,
  };
}

export function setContentCardsSuccess(contentCards) {
  return {
    type: types.GET_CONTENT_CARDS_SUCCESS,
    contentCards,
  };
}
export function setContentCardsFailure() {
  return {
    type: types.GET_CONTENT_CARDS_FAILURE,
  };
}
