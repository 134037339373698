import React from "react";
import PropTypes from "prop-types";
import BackIcon from "./Icons/BackIcon";

const renderTeamDetails = (teamName, teamLogo, position = "left") => (
  <div
    className={`flex items-center justify-end min-w-96 max-w-96 ${
      position === "right" ? "flex-row-reverse" : ""
    }`}
  >
    <span className="text-white text-xs leading-loose">{teamName || `TBC`}</span>
    <div className="h-24 w-24 mx-7">
    <img className="h-full w-full" src={teamLogo} alt="" />
    </div>
  </div>
);

const UpcomingFixtureRow = ({
  dateTime,
  matchTime,
  homeTeamName,
  homeTeamUrl,
  awayTeamName,
  awayTeamUrl,
  className,
  navigationHandler,
  matchID,
  showLive
}) => (
  <div className={`h-56 w-full bg-transparent flex flex-col items-center justify-center pb-8 mb-8 ${className}`}>
    <span className="text-xs text-white leading-loose">{dateTime}</span>
    <div className="w-full h-24 mt-8 flex items-center justify-center relative">
      {renderTeamDetails(homeTeamName, homeTeamUrl)}
      <div className="h-20 w-48 bg-medium-dark-grey text-center">
       {showLive ?
        <span className="text-error-red text-2xs font-MarkProBold leading-normal">LIVE</span>
       :
        <span className="text-white text-2xs font-MarkProBold leading-normal">{matchTime}</span>
       }
      </div>
      {renderTeamDetails(awayTeamName, awayTeamUrl, "right")}
      <div onClick={() => navigationHandler(matchID)} className="text-white absolute pin-r rotate-right pl-8 cursor-pointer">
        <BackIcon className="drop-right" />
      </div>
    </div>
  </div>
);

UpcomingFixtureRow.defaultProps = {
  className: '',
  navigationHandler: () => {},
  showLive: false
};

UpcomingFixtureRow.propTypes = {
  /** Time of the match in string format */
  dateTime: PropTypes.string,
  /** Display name of the home team */
  homeTeamName: PropTypes.string,
  /** Display image of the home team */
  homeTeamUrl: PropTypes.string,
  /** Display name of the away team */
  awayTeamName: PropTypes.string,
  /** Display image of the home team */
  awayTeamUrl: PropTypes.string
};

export default UpcomingFixtureRow;
