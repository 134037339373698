import React from "react";
import PropTypes from 'prop-types';
import BackIcon from "./Icons/BackIcon";

class PreviousMeetingsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSummary: false };
  }

  renderTeamDetails = (teamName, teamLogo, position = "left") => (
    <div
      className={`flex items-center justify-end min-w-96 max-w-96 ${
        position === "right" ? "flex-row-reverse" : ""
      }`}
    >
      <span className="text-white text-xs leading-loose">{teamName}</span>
      <div className="h-24 w-24 mx-7">
      <img className="h-full w-full" src={teamLogo} alt="" />
      </div>
      
    </div>
  );

  toggleSummary = () => {
    this.setState({ showSummary: !this.state.showSummary });
  };

  render() {
    const {
      dateTime,
      result,
      homeTeamUrl,
      homeTeamName,
      awayTeamName,
      awayTeamUrl,
      summary,
      matchVenue,
      className
    } = this.props;

    const dropDownClass = this.state.showSummary ? 'drop-up' : 'drop-down'

    return (
      <div className={`w-full bg-transparent flex flex-col items-center justify-center mt-8 ${className}`}>
       
       <div className="w-full h-56 flex flex-col items-center justify-center ">
        <span className="text-xs text-white pb-8">{dateTime}</span>
        <div className="w-full flex items-center justify-center relative">
          {this.renderTeamDetails(homeTeamName, homeTeamUrl)}
          {result}
          {this.renderTeamDetails(awayTeamName, awayTeamUrl, "right")}
          <div className="text-white absolute pin-r rotate-right pl-8 cursor-pointer">
            <BackIcon className={dropDownClass} onClick={() => this.toggleSummary()} />
          </div>
        </div>
        </div>
       
       
       
        {this.state.showSummary ? (
          <div className="w-full flex flex-col items-center justify-center pb-8">
            <span className="text-white font-MarkProBold text-2xs mb-8 mt-16">
              {matchVenue}
            </span>
            {summary}
          </div>
        ) : null}
      </div>
    );
  }
}

PreviousMeetingsRow.propTypes = {
    /** The date time at which the match was played */
    dateTime: PropTypes.string,
     /** The image url of the home team */
    homeTeamUrl: PropTypes.string,
    /** The display name of the home team */
    homeTeamName: PropTypes.string,
    /** The display name of the away team */
    awayTeamName: PropTypes.string,
    /** The image url of the away team */
    awayTeamUrl: PropTypes.string,
    /** Name of the stadium where the match was played */
    matchVenue: PropTypes.string

}

PreviousMeetingsRow.defaultProps = {
  className: ""
}

export default PreviousMeetingsRow;
