import React from 'react';

const StatsBadge = ({ logoUrl, className })  => (
    <div className={`h-48 md:h-72 w-48 md:w-72  flex items-center justify-center ${className}`}>
    <img className="w-auto h-full" src={logoUrl} alt=""/>
    </div>

);
StatsBadge.defaultProps = {
className: ''
}

export default StatsBadge;