import { init } from 'lib/braze';
import * as braze from '@braze/web-sdk';
import { isSSRUserAgent } from './userAgent';
import defaultConfig from '../constants/defaultConfig';

function brazeStore() {
  const initData = {
    isInitialized: false,
  };

  let storeData = {};

  const setData = (data) => {
    storeData = { ...storeData, ...data };
  };
  const getData = (key) => {
    return storeData[key];
  };

  return {
    init(data) {
      setData({ ...initData, ...data });
    },
    setData(newState) {
      setData(newState);
    },
    getData(key) {
      return getData(key);
    },
  };
}

const brazeState = brazeStore();
brazeState.init();

const getIsBrazeReady = () => brazeState.getData('isInitialized');

function isBrazeEnabled({ featureConfig, userLoggedIn }) {
  const { enabled, disableLoggedOutUsers } = featureConfig || {};
  return (
    enabled &&
    navigator.cookieEnabled &&
    !isSSRUserAgent() &&
    (userLoggedIn || (!userLoggedIn && !disableLoggedOutUsers))
  );
}

export function setupBraze({ featureConfig, userId, userLoggedIn }) {
  const isBrazeReady = getIsBrazeReady();
  if (isBrazeEnabled({ featureConfig, userLoggedIn })) {
    const brazeUserId = getBrazeUserId(userId, userLoggedIn);

    if (isBrazeReady) {
      if (brazeUserId) {
        // if brazeUserId is truthy = user logged in
        braze.changeUser(brazeUserId);
        braze.openSession();
      } else {
        // if brazeUserId is falsy = user not logged in
        braze.wipeData();
        braze.openSession();
      }
    } else {
      // const isProd = process.env.NODE_ENV === 'production';
      const { sessionTimeoutInSeconds = 180 } = featureConfig;
      const { env = 'prod' } = defaultConfig;
      const isInitialized = init({
        featureConfig,
        userId: brazeUserId,
        initOption: {
          enableLogging: env !== 'prod',
          allowUserSuppliedJavascript: true,
          sessionTimeoutInSeconds,
        },
        option: { automaticallyShowInAppMessages: true },
      });
      // braze.requestPushPermission();

      brazeState.setData({ isInitialized });
    }
  } else {
    // if braze shouldn't be enabled
    if (isBrazeReady) {
      braze.destroy();
      braze.wipeData();
      braze.disableSDK();
    }
    brazeState.setData({ isInitialized: false });
  }
}

export function setBrazeUserProfile(userProfile) {
  const isBrazeReady = getIsBrazeReady();
  if (isBrazeReady) {
    const brazeUser = braze.getUser();
    if (brazeUser && userProfile) {
      const { firstName } = userProfile;

      firstName &&
        typeof brazeUser.setFirstName === 'function' &&
        brazeUser.setFirstName(firstName);
    }
  }
}

function getBrazeUserId(userId, userLoggedIn) {
  const brazeUserId = userLoggedIn ? userId : false;
  return brazeUserId;
}

// const genderMap = new Map([
//   ['female', 'f'],
//   ['male', 'm'],
//   ['not-applicable', 'n'],
//   ['non-binary', 'o'],
// ]);

export function brazeEvent({ eventName, ...payload }) {
  const { eventProps } = payload || {};
  const isBrazeReady = getIsBrazeReady();
  isBrazeReady && eventName && braze.logCustomEvent(eventName, eventProps);
}
